import React from 'react';

const OkayHero = props => {
  return(
    <div className="aboutDetails">
      <h1>Okay, Hero</h1>
      <a href="https://bulletpoints.itch.io/okayhero"><img alt="book cover for Okay Hero features a skeleton horse" src="https://res.cloudinary.com/hbcqwz75s/image/upload/v1575501738/promo_images/OKHeroCoverWithNames-791x1024_skokw2.jpg" id="okayheroimg"></img></a>
      <p>Violent. Funny. Weird. Sexy. Political. Historical. Gothic. Incomprehensible. Brilliant. Awful. Original. Lazy. The very best of games. The very worst of games. <em>Metal Gear Solid</em> is all of these things and more. For twenty years, it has incited as much as it has entertained—thrilled and inflamed in equal measure. It begs analysis while at the same time defying simple definitions. To make any sense of <em>Metal Gear Solid</em> would surely take an entire book.</p>
      <p>Hence <em><a href="https://bulletpoints.itch.io/okayhero">Okay, Hero</a></em>. By looking at how its identity has gradually changed, and the social and pop cultural events that have influenced it, we, the Bullet Points editorial team of Reid McCarter, Ed Smith, and Astrid Rose attempt in Okay, Hero to pin down precisely what makes <em>Metal Gear Solid Metal Gear Solid</em>; to identify the inspirations behind its bizarre sci-fi design, untangle its fraught relationships with sex and gender, and hone in on its ideas about the modern world.</p>
      <p><em><a href="https://bulletpoints.itch.io/okayhero">Okay, Hero</a></em> includes an introduction and 12 chapters–two each on <em>Metal Gear Solid, Sons of Liberty, Snake Eater, Guns of the Patriots, Peace Walker</em>, and <em>The Phantom Pain</em> written by Ed Smith and Reid McCarter and edited by Ed, Reid, and Astrid Rose.</p>
      <p>{`///`}</p>
      <p><em><a href="https://bulletpoints.itch.io/okayhero">Okay, Hero</a></em> features a cover illustration by <a href="https://www.instagram.com/cadmiumcoffee/">Sishir Bommakanti</a> and interior design by <a href="https://twitter.com/Dan_Solberg">Dan Solberg</a>, and is <a href="https://bulletpoints.itch.io/okayhero">available sold digitally in EPUB, MOBI, and PDF formats for $5.99 USD on Itch.io.</a></p>
    </div>
    )
}

export default OkayHero
